import React, { useState, useEffect } from 'react';
import logo from './../logo.svg';
function Header() {
    const [addClass, setAddClass] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 150) {
                setAddClass(true);
            } else {
                setAddClass(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <section className={`header ${addClass?'collapse':''}`} >
            <img src={logo} className="App-logo" alt="logo" width={200} />
            <div className='title'>
                The Tech Guy
            </div>
            <div className='menu'>
                {/*<nav>
                <ul>
                    <li><a href='#'>Home</a></li>
                    <li><a href='#'>About</a></li>
                    <li><a href='#'>Contact</a></li>
                </ul>
    </nav>*/}
            </div>
        </section>)
}
export default Header;