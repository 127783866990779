
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Hero from './components/Hero';
import TwoColumn from './components/TwoColumn';
import Serviceimage from './services.jpeg';
import superkraft from './superkraft.jpeg';
import app from './app.jpeg';
import advice from './advice.jpeg';
import web from './web.jpeg';
import contact from './contact.jpeg';

function App() {
  return (
    <div className="App">
      <Header />
      <div className="main">
        <Hero />
        <TwoColumn>
          <div className="center">
            <h2>Om oss</h2>
            <p>
            Välkommen till The Tech Guy, ett nystartat konsultföretag inom tekniksektorn. Drivet av Marcus, som har över 25 års erfarenhet av teknikbranschen, strävar vi efter att leverera tjänster och rådgivning av högsta kvalitet. Vår breda erfarenhet från olika typer av företag, från startups till större etablerade organisationer, har gett oss en djup förståelse för tekniska utmaningar och möjligheter.

Vi fokuserar på att hjälpa våra klienter att navigera i den digitala världen, oavsett om det handlar om små justeringar eller större transformationsprojekt. Genom diskret och skräddarsydd rådgivning strävar vi efter att skapa lösningar som är både innovativa och värdefulla.

För mer information eller för att boka ett möte, tveka inte att kontakta oss..
            </p>
          </div>
        </TwoColumn>
        <hr />
        <TwoColumn class={'even'}>
          <div style={{ backgroundImage: `url(${contact})` }} className='backgroundImage'>
          </div>
          <div>
            <h2>Våra tjänster</h2>
            <p>På The Tech Guy är vi stolta över att erbjuda ett dynamiskt utbud av tjänster och rådgivning anpassade för att möta de unika behoven i er verksamhet. Vi arbetar kontinuerligt med att utveckla och förbättra våra erbjudanden för att säkerställa att de bäst stödjer era arbetsprocesser. För att hålla er uppdaterade om de senaste tilläggen och uppdateringarna, besök gärna vår webbsida regelbundet.</p>
            {/*<p>The Tech Guy erbjuder ett brett sortiment av tjänster inom utveckling inom app, web, IT, AI och har ett flertal tjänster på gång för att underlätta just ert arbete.</p>*/}
          </div>
  </TwoColumn>
  <hr/>
       {/* <TwoColumn class={'even'}>
          <div style={{ backgroundImage: `url(${contact})` }} className='backgroundImage'>
          </div>
          <div>
            <h2>Våra tjänster</h2>
            <p>The Tech Guy erbjuder ett brett sortiment av tjänster inom utveckling inom app, web, IT, AI</p>
            <p>
              <ul>
                <li>Teknisk rådgivning</li>
                <li>Event tech</li>
                <li>Smarta hem</li>
                <li>Elektronik</li>
                <li>Digital transformation</li>
                <li>Webbutveckling</li>
                <li>IT-nära tjänster</li>
                <li>AI och maskininlärning</li>
                <li>Förvaltning och underhåll</li>
                <li>Proof of concept utveckling</li>
              </ul>
            </p>
          </div>
  </TwoColumn>*/}
        <TwoColumn>
        <div className="center">
            <h2>Jag kallar mig för jack of all trades</h2>
            <p>
            <i>Vårt motto: "Jack of all trades, master of none, though oftentimes better than master of one."</i>
<p>
På The Tech Guy tror vi på kraften av bred kompetens. Med omfattande erfarenhet inom flera tekniska och affärsmässiga områden är vi utrustade för att erbjuda stöttning både i bredd och på spetsnivå. Vår erfarenhet från att arbeta med allt från stora, etablerade företag till nystartade startups ger oss en unik förmåga att förstå och uppfylla olika typer av behov och utmaningar.

Vi är här för att hjälpa ert företag att navigera i teknikens snabbföränderliga värld, med tjänster som anpassas för att stärka och effektivisera er verksamhet.
</p>
            </p>
          </div>
        </TwoColumn>
       {/** <TwoColumn class={'even'}>
          <div>
            <h2>Människor är dyra, hårdvara är billigt</h2>
            <p>Är tyvärr en sanning. Investering i personal och konsulter, kan vara väldigt dyrt.
              Men risken är också att man stirrar sig blind på siffror och missar det långa perspektivet.
              Oavsett om ni bygger på en gammal plattform, är eller vill testa något nytt, så kostar det.
              The Tech Guy erbjuder flexibla prisplaner beroende på projekt och engagemang. Kör ni öppna kort med oss, så kör jag öppna kort med er!</p>
          </div>
          <div style={{ backgroundImage: `url(${superkraft})` }} className='backgroundImage'>

          </div>
        </TwoColumn> 
        <hr />
        <TwoColumn>
          <div className='center'>
          <h2>Områden vi erbjuder tjänster</h2>
          <p>Några av våra områden som The Tech Guy erbjuder tjänster inom.</p>
          </div>
        </TwoColumn>
        <TwoColumn>
        <div style={{ backgroundImage: `url(${advice})` }} className='backgroundImage'>
          </div>
          <div>
        
            <h2>Rådgivning</h2>
            <p>Det är svårt att välja, jag vet. Men lugn, jag hjälper er!
              Med erfarenhet av att jobba med stora företag och startups och vet vad som krävs för att lyckas.
              Låt oss hjälpa er med att välja rätt teknik, rätt plattform och rätt leverantör eller varför inte bara få en second opinion på er design och UX.
              Har du bara en bra idé och vill ha hjälp att förverkliga den? Jag kan hjälpa dig med det med!
            </p>
          </div>
        </TwoColumn>
        <TwoColumn>
          <div>
            <h2>Apputveckling</h2>
            <p>Jag har lång erfanhet av mobilutveckling. Väldigt lång. Från WAP till APP så att säga.
              Idag erbjuder jag primärt med en teknik som kallas för React Native. Det är tidseffektivt för kunden och ger bra prestanda. 
              Däremot så känner vi ibland, att väldigt mycket tid läggs för väldigt lite, där så kallade PWA istället erbjuder mycket snabbare värde för pengarna.</p>
            <p>Vet ni inte vad ska välja? Hör av er så hjälper jag er!</p>
          </div>
          <div style={{ backgroundImage: `url(${app})` }} className='backgroundImage'>
          </div>
        </TwoColumn>
        <TwoColumn>
        <div style={{ backgroundImage: `url(${Serviceimage})` }} className='backgroundImage'>
          </div>
          <div>
          
            <h2>Event tech</h2>
            <p>Event och marketing har varit min starka sida sedan länge. Event tech är något jag brinner lite extra för.
              Det kan vara svårt att hitta balansgången mellan ekonomi och effekt vid kortare event. Maxa effekten med rätt teknik och rätt lösning.
              Med egenutvecklade tjänster eller implementering av 3e part, kan jag erbjuda budgeteffektiva lösningar för marknadsevent, mässor eller kontor.
              Engagemang skapar lojalitet och mervärde, och ibland bara glädje.
              Spel online, spel offline, Quiz, installationer eller varför inte möbler, jag har gjort allt och kan erbjuda er allt.</p>
          </div>
        </TwoColumn>
        <TwoColumn>
          <div>
            <h2>Webbutveckling</h2>
            <p>Jag har jobbat i över 25 år med webbutveckling. Såg födelsen av webben och har varit med sedan dess.
            Stora giganterna som kom och dog ut. Dom smarta lösningarna som blev slukade och bortglömda.
            Jag har sett det mesta och kan hjälpa er att undvika de vanligaste fällorna.
            Med starkt öga för UX och design, tillsammans med en förkärlek för scriptning, kan jag erbjuda er en modern och snabb webbplats.
            Jag har också erfanehet från många olika plattformar, från Wordpress till Optimizely, från Strapi till Contentful.</p>
          </div>
          <div style={{ backgroundImage: `url(${web})` }} className='backgroundImage'>
          </div>
        </TwoColumn>
        <TwoColumn>
        <div className='center'>
            <h2>Jag är inte ensam</h2>
            <p>Vi tror inte ensam är stark.
              Ett team är starkare än en individ. Ett nätverk är starkare än ett team.
              Ett brett nätverk av partners som vi samarbetar med.
              Därigenom kan vi erbjuda det mesta inom teknik och utveckling till design och copy. Vi kan till och med erbjuda snickeri.
              Vi hjälper varandra att lyckas och vi hjälper er att lyckas.
            </p>
          </div>
          
        </TwoColumn>*/}
      </div>
      <hr />
      <Footer />
    </div>
  );
}

export default App;
