import footer from './../footer.jpeg';
function Footer(){
    return (
        <footer>
            <div>
                <h4>Kontakt</h4>
                The Tech Guy AB<br/>
                Skjutbanevägen 13<br/>
                446 33 Älvängen<br/>
                Telefon: 076-142 86 86<br/>
               <span>Epost:  <a href="mailto:contact@thetechguy.se">contact@thetechguy.se</a></span> <br/>
                
            </div>
            <div>
                <img src={footer} width={200}/>
            </div>
            <div>
                <h4>Mer om oss</h4>
                The Tech Guy AB är ett Göteborgsbaserat konsultföretag som erbjuder utveckling och rådgivning inom IT och teknik med
                lång erfarenhet av att hjälpa företag att lyckas med sin digitala transformation.<br/>
                Org.nr: 559476-8425<br/>
                Godkänd för F-skatt
                
            </div>
        </footer>
    )
}
export default Footer;